// Generated by Framer (c75d380)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ZPXOgpfg3", "JbsgXtUBM"];

const serializationHash = "framer-pGz9s"

const variantClassNames = {JbsgXtUBM: "framer-v-ghdvy8", ZPXOgpfg3: "framer-v-195nwu"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Burger: "ZPXOgpfg3", X: "JbsgXtUBM"}

const getProps = ({color, height, id, tap, width, ...props}) => { return {...props, iWF8EWIN1: color ?? props.iWF8EWIN1 ?? "rgb(136, 136, 136)", TBgHLr7MW: tap ?? props.TBgHLr7MW, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ZPXOgpfg3"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any;color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, TBgHLr7MW, iWF8EWIN1, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ZPXOgpfg3", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTaplbafx3 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (TBgHLr7MW) {
const res = await TBgHLr7MW(...args);
if (res === false) return false;
}
setVariant("JbsgXtUBM")
})

const onTap1e73tm9 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (TBgHLr7MW) {
const res = await TBgHLr7MW(...args);
if (res === false) return false;
}
setVariant("ZPXOgpfg3")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-195nwu", className, classNames)} data-framer-name={"Burger"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"ZPXOgpfg3"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTaplbafx3} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({JbsgXtUBM: {"data-framer-name": "X", onTap: onTap1e73tm9}}, baseVariant, gestureVariant)}><motion.div className={"framer-17gbhw8"} layoutDependency={layoutDependency} layoutId={"zhSzcdHwz"}><motion.div className={"framer-bnb0ed"} data-framer-name={"Bottom"} layoutDependency={layoutDependency} layoutId={"brprbhwGV"} style={{backgroundColor: iWF8EWIN1, rotate: 0}} variants={{JbsgXtUBM: {rotate: -45}}}/><motion.div className={"framer-1akbo33"} data-framer-name={"Mid"} layoutDependency={layoutDependency} layoutId={"oOwMjfsg4"} style={{backgroundColor: iWF8EWIN1, opacity: 1}} variants={{JbsgXtUBM: {opacity: 0}}}/><motion.div className={"framer-1vgkbi9"} data-framer-name={"Top"} layoutDependency={layoutDependency} layoutId={"TzX8Z2qbZ"} style={{backgroundColor: iWF8EWIN1, rotate: 0}} variants={{JbsgXtUBM: {rotate: 45}}}/></motion.div></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-pGz9s[data-border=\"true\"]::after, .framer-pGz9s [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-pGz9s.framer-ihziss, .framer-pGz9s .framer-ihziss { display: block; }", ".framer-pGz9s.framer-195nwu { cursor: pointer; height: 32px; overflow: hidden; position: relative; width: 32px; }", ".framer-pGz9s .framer-17gbhw8 { flex: none; height: 18px; left: calc(50.00000000000002% - 24px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 18px / 2); width: 24px; }", ".framer-pGz9s .framer-bnb0ed { bottom: 0px; flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; }", ".framer-pGz9s .framer-1akbo33 { flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; top: calc(50.00000000000002% - 2px / 2); }", ".framer-pGz9s .framer-1vgkbi9 { flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; }", ".framer-pGz9s.framer-v-ghdvy8 .framer-bnb0ed { bottom: unset; top: calc(50.00000000000002% - 2px / 2); }", ".framer-pGz9s.framer-v-ghdvy8 .framer-1akbo33 { left: unset; width: 2px; }", ".framer-pGz9s.framer-v-ghdvy8 .framer-1vgkbi9 { left: unset; right: -1px; top: calc(50.00000000000002% - 2px / 2); width: 26px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"JbsgXtUBM":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"TBgHLr7MW":"tap","iWF8EWIN1":"color"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framercfd1N0PVy: React.ComponentType<Props> = withCSS(Component, css, "framer-pGz9s") as typeof Component;
export default Framercfd1N0PVy;

Framercfd1N0PVy.displayName = "Elements/Menu Icon Copy";

Framercfd1N0PVy.defaultProps = {height: 32, width: 32};

addPropertyControls(Framercfd1N0PVy, {variant: {options: ["ZPXOgpfg3", "JbsgXtUBM"], optionTitles: ["Burger", "X"], title: "Variant", type: ControlType.Enum}, TBgHLr7MW: {title: "Tap", type: ControlType.EventHandler}, iWF8EWIN1: {defaultValue: "rgb(136, 136, 136)", title: "Color", type: ControlType.Color}} as any)

addFonts(Framercfd1N0PVy, [])